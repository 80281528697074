export const GLOBAL_BASE_URL = process.env.VUE_APP_API_BASE_URL;
export const AUTH_BASE_URL = process.env.VUE_APP_AUTH_BASE_URL;
export const COBRANDED_BASE_URL = process.env.VUE_APP_COBRANDED_AUTH_BASE_URL;
export const AUTH_BASE_URL_DYNAMIC = AUTH_BASE_URL;
  import   {isCobrandedDomain } from '../commonFunctions';
//===========================================store function api=========================================

/* Dynamic base path API's start */
export const EP_USER_LOGIN = `${AUTH_BASE_URL_DYNAMIC}auth-server/oauth/token`;
export const EP_REGISTRATION = `${AUTH_BASE_URL_DYNAMIC}v2/api/customer/register`;
export const EP_GET_ACCOUNTS_CONNECTED = `${AUTH_BASE_URL_DYNAMIC}flavors/v2/api/brokers/listForRegistration?${
  !isCobrandedDomain() ? "all=true" : "flavorId={{flavorId}}"
}`;
export const EP_GET_LOGIN_FLAVOR_LIST = `${AUTH_BASE_URL_DYNAMIC}flavors/api/flavors/light/secure`;
export const EP_GET_FLAVOR_LIST = `${AUTH_BASE_URL_DYNAMIC}flavors/api/flavors/detect/light`;
export const EP_GET_ALL_BROKERS_LIST = `${AUTH_BASE_URL_DYNAMIC}brokers/api/brokers/available`;
/* Dynamic base path API's end */

export const EP_DEMO_ACCOUNT = `${AUTH_BASE_URL}api/customer/{{customerId}}/account/demo`;

//leader API old
export const EP_GET_TRADER_DETAIL = `${AUTH_BASE_URL}v2/api/providers/{{TradeID}}/thi/init?accessingFlavorId={{flavorId}}&flavor={{region}}`;
export const EP_GET_TRADER_DETAIL_SECURED = `${AUTH_BASE_URL}v2/api/user/providers/{{TradeID}}/thi/init?accessingFlavorId={{flavorId}}&flavor={{region}}`;
export const EP_GET_TOP_TRADER_LIST = `${AUTH_BASE_URL}api/providers/performance/topTraders/search?flavorId={{flavorId}}&accessingFlavorId={{accessingFlavorId}}`;

export const EP_GET_FILTERED_ALL_TRADER_LIST = `${AUTH_BASE_URL}api/providers/performance/topTraders/{{ID}}/search?flavorId={{flavorId}}&accessingFlavorId={{accessingFlavorId}}`;
export const EP_GET_SEARCH_TRADER_LIST = `${AUTH_BASE_URL}traderstats/v2/api/providers/search?accessingFlavorId={{accessingFlavorId}}&limit=5&name={{name}}&flavor={{flavor}}`;
export const EP_GET_COMBOS_LIST = `${AUTH_BASE_URL}api/providers/performance/topCombos/search?flavorId=1`;
export const EP_GET_TOP_FIVE_FOLLOWERS_LIST = `${AUTH_BASE_URL}traders/api/providers/{{TradeID}}/top5livefollowers`;
export const EP_GET_FOLLOWERS_LIST = `${AUTH_BASE_URL}traders/api/providers/{{TradeID}}/livefollowers?page={{page}}&size=10`;
export const EP_GET_TRADE_HISTORY_LIST = `${AUTH_BASE_URL}trading/api/providers/{{TradeID}}/trades/history?timeframe={{timeframe}}&page={{page}}&size={{size}}&sort={{sort}}`;
export const EP_GET_TRADE_HISTORY_CURRENCY_LIST = `${AUTH_BASE_URL}trading/api/providers/{{TradeID}}/trades/history?timeframe={{timeframe}}&page={{page}}&size={{size}}&sort={{sort}}&cid={{id}}`;
export const EP_GET_OPEN_POSITION_LIST = `${AUTH_BASE_URL}trading/api/providers/{{TradeID}}/trades/open/all?timeframe=10000&calculateProfit=true`;
export const EP_GET_CALCULATE_PIP = `${AUTH_BASE_URL}utils-service/api/forex/calculators/pip?currency={{currency}}&lot={{lot}}&lotSize={{lotSize}}&pips={{pips}}&baseCurrency={{baseCurrency}}`;
export const EP_GET_CALCULATE_MARGIN = `${AUTH_BASE_URL}utils-service/api/forex/calculators/margin?currency={{currency}}&lotSize={{lotSize}}&leverage={{leverage}}&baseCurrency={{baseCurrency}}`;
export const EP_GET_CALCULATE_PROFIT = `${AUTH_BASE_URL}utils-service/api/forex/calculators/profit?currency={{currency}}&lot={{lot}}&lotSize={{lotSize}}&openPrice={{openPrice}}&closePrice={{closePrice}}&baseCurrency={{baseCurrency}}`;
export const EP_GET_CONVERT_CURRENCY = `${AUTH_BASE_URL}utils-service/api/converters/currency?amount={{amount}}&currencyFrom={{currencyFrom}}&currencyTo={{currencyTo}}`;
export const EP_GET_CONVERTER_CURRENCY = `${AUTH_BASE_URL}utils/api/tools/converterCurrencies`;
export const EP_GET_PIPS_CURRENCY = `${AUTH_BASE_URL}utils/api/tools/calculatorCurrencies`;
export const EP_GET_BASECURRENCY = `${AUTH_BASE_URL}utils-service/api/tools/calculatorBaseCurrencies`;
export const EP_GET_CURRENCY_PAIRS_LIST = `${AUTH_BASE_URL}utils/api/currencies/details?feedGroupId={{feedGroupId}}`;
export const EP_GET_TRADING_PROFIT_CHART_DATA = `${AUTH_BASE_URL}traderstats/v2/api/providers/{{TradeID}}/performance/chart?type={{type}}&timespan=DAILY&timeframe={{timeframe}}`;
export const EP_GET_TRADING_PROFIT_CHART_DATA_WITH_CURRENCY = `${AUTH_BASE_URL}traderstats/v2/api/providers/{{TradeID}}/performance/chart?type={{type}}&timespan=DAILY&timeframe={{timeframe}}&cid={{cid}}`;
export const EP_GET_TRADING_PERFORMANCE_CHART_DATA = `${AUTH_BASE_URL}traderstats/v2/api/providers/{{TradeID}}/performance/chart?type={{type}}&timespan={{timespan}}&timeframe={{timeframe}}`;
export const EP_GET_TRADING_PERFORMANCE_RANKING_CHART_DATA = `${AUTH_BASE_URL}traderstats/v2/api/providers/{{TradeID}}/performance/chart?type=RANKING&timespan=&timeframe={{timeframe}}&flavor=global`;
export const EP_GET_CHART_DATA = `${AUTH_BASE_URL}traderstats/v2/api/providers/{{TradeID}}/performance/chart?`;

/*** Social Start ***/
export const EP_GET_SOCIAL_LIST = `${AUTH_BASE_URL}api/newsfeed/provider/{{TradeID}}?size=10`;
// export const EP_GET_SOCIAL_AUTOLOAD_LIST = `${AUTH_BASE_URL}api/newsfeed/provider/{{TradeID}}?size=10&eventId={{EventID}}`
export const EP_GET_SOCIAL_LOADMORE_LIST = `${AUTH_BASE_URL}api/newsfeed/provider/{{TradeID}}?size=10&eventId={{EventID}}`;
export const EP_GET_SOCIAL_TYPE_LIST = `${AUTH_BASE_URL}socialevents/api/traders/{{TradeID}}/events?type=STATUS_UPDATE`;
export const EP_GET_SOCIAL_TYPE_COMMENT_LIST = `${AUTH_BASE_URL}api/user/newsfeed/{{ZuluEventUD}}`;
export const EP_GET_SOCIAL_COMMENT_LIST = `${AUTH_BASE_URL}api/user/newsfeed/{{ZuluEventUD}}`;
export const EP_GET_SOCIAL_HASTAG_RIGHT_LIST = `${AUTH_BASE_URL}socialevents/api/user/{{ZuluID}}/hashtags?size=10`;
export const EP_GET_SOCIAL_HASTAG_LIST = `${AUTH_BASE_URL}api/user/newsfeed?size=10&hashtag={{Hastag}}`;
export const EP_GET_SOCIAL_HASTAG_LOADMORE_LIST = `${AUTH_BASE_URL}api/newsfeed/provider/{{TradeID}}?size=10&eventId={{EventID}}&hashtagToFilter={{Hastag}}`;
// export const EP_POST_SOCIAL_TOP_COMMENT_SIMPLE = `${AUTH_BASE_URL}socialevents/api/events/save`
export const EP_POST_SOCIAL_TOP_COMMENT = `${AUTH_BASE_URL}socialevents/v2/api/events/save`;
export const EP_SOCIAL_FEED_REPORT = `${AUTH_BASE_URL}socialevents/api/events/save`;
export const EP_GET_SOCIAL_TOP_COMMENT_PUBLIC_NAME = `${AUTH_BASE_URL}api/newsfeed/user/search?type=PUBLIC_NAME&searchTerm={{publicName}}`;
export const EP_GET_SOCIAL_TOP_COMMENT_HASHTAG = `${AUTH_BASE_URL}api/newsfeed/user/search?type=HASHTAG&searchTerm={{hastagName}}`;
export const EP_GET_SOCIAL_LIVE_FOLLOWERS_LIST = `${AUTH_BASE_URL}traders/api/providers/{{TradeID}}/livefollowers?page={{page}}&size=10`;
export const EP_GET_SOCIAL_FEED_PAGE_LIST = `${AUTH_BASE_URL}api/user/newsfeed?size=10`;
export const EP_GET_SOCIAL_FEED_PAGE_LOADMORE_LIST = `${AUTH_BASE_URL}api/user/newsfeed?size=10&eventId={{EventID}}`;
export const EP_GET_MY_FEED_PAGE_LIST = `${AUTH_BASE_URL}api/customer/newsfeed?size=10&own=true`;
export const EP_GET_MY_FEED_PAGE_LOADMORE_LIST = `${AUTH_BASE_URL}api/customer/newsfeed?size=10&own=true&eventId={{EventID}}`;
export const EP_GET_MY_PAGE_HASTAG_LOADMORE_LIST = `${AUTH_BASE_URL}api/customer/newsfeed?size=10&own=true&eventId={{EventID}}&hashtag={{Hastag}}`;
export const EP_GET_SOCIAL_HASTAG_MY_FEED_LIST = `${AUTH_BASE_URL}api/customer/newsfeed?size=10&hashtag={{Hastag}}`;

export const EP_GET_SOCIAL_FEED_PAGE_HASTAG_RIGHT_LIST = `${AUTH_BASE_URL}socialevents/api/hashtags?size=10`;
export const EP_GET_SOCIAL_FEED_PAGE_HASTAG_LOADMORE_LIST = `${AUTH_BASE_URL}api/user/newsfeed?size=10&eventId={{EventID}}&hashtag={{Hastag}}`;
export const EP_GET_SOCIAL_LIKE = `${AUTH_BASE_URL}socialevents/api/newsfeed/{{SocialEventID}}/{{status}}`;
export const EP_GET_SOCIAL_DELETE = `${AUTH_BASE_URL}socialevents/api/newsfeed/{{SocialEventID}}/comment`;
export const EP_POST_SOCIAL_COMMENT = `${GLOBAL_BASE_URL}socialevents/v2/api/newsfeed/{{SocialEventID}}/comment`;
export const EP_POST_SOCIAL_COMMENT_EDIT = `${GLOBAL_BASE_URL}socialevents/v2/api/newsfeed/{{SocialEventID}}/comment`;
export const EP_GET_SOCIAL_FEED_MY_HASTAGS = `${AUTH_BASE_URL}socialevents/api/user/hashtags?size=100`;
export const EP_GET_SOCIAL_FEED_HASTAG_FOLLOW = `${AUTH_BASE_URL}socialevents/api/user/hashtags/{{hastagID}}`;
/*** Social End ***/

export const EP_GET_TRADING_CHART_DATA = `${AUTH_BASE_URL}traderstats/v2/api/providers/{{TradeID}}/performance/chart?type={{type}}&timespan={{timespan}}&timeframe={{timeframe}}`;
export const EP_GET_TRADING_DRAWDOWN_CHART_DATA = `${AUTH_BASE_URL}traderstats/v2/api/providers/{{TradeID}}/performance/chart?type={{type}}&timespan={{timespan}}&timeframe={{timeframe}}`;
export const EP_GET_TRADING_SLIPPAGE_CHART_DATA = `${AUTH_BASE_URL}traderstats/v2/api/providers/{{TradeID}}/performance/chart?type=SLIPPAGE`;
export const EP_GET_FOLLWERS_FOLLOWING_LIST = `${AUTH_BASE_URL}followers/api/brokerAccounts/{{TradeID}}/providers/following?page=0&size=2000`;
export const EP_GET_FOLLWERS_HAS_FOLLOWED_LIST = `${AUTH_BASE_URL}followers/api/brokerAccounts/{{TradeID}}/providers/hasFollowed?page=0&size=2000`;
export const EP_GET_FOLLWERS_CHART_DATA = `${AUTH_BASE_URL}trading/api/followers/{{BrokerId}}/providers/{{TradeID}}/history/graph`;
export const EP_GET_TRADER_COMPARE_PERMORMANCE_DATA = `${AUTH_BASE_URL}v2/api/providers/performance/search`;
export const EP_GET_FOLLOWERS_COMPARE_LIST = `${AUTH_BASE_URL}traderstats/v2/api/providers/search`;
export const EP_GET_ALL_TRADERS_LIST = `${AUTH_BASE_URL}v2/api/providers/performance/search`;
export const EP_GET_COUNTRIES_LIST = `${AUTH_BASE_URL}traderstats/api/providers/performance/countries?flavor=global`;
export const EP_GET_ALL_COUNTRY_LIST = `${AUTH_BASE_URL}utils/api/countries?active=false`;
export const EP_GET_CURRENCIES_LIST = `${AUTH_BASE_URL}traderstats/v2/api/providers/performance/currencies?flavor=global`;
export const EP_ADD_TRADER_CUSTOM_VIEW = `${AUTH_BASE_URL}traders/api/zuluAccounts/performanceUserViews`;
export const EP_GET_CUSTOM_VIEW_LIST = `${AUTH_BASE_URL}traders/api/zuluAccounts/performanceFilters`;
export const SOCIAL_REPORT = `${AUTH_BASE_URL}translators/api/translate`;
export const EP_GET_COUNTRY_LIST = `${AUTH_BASE_URL}utils/api/countries?getOnlyEuMmber=false`;
export const EP_GET_USER_DETAIL = `${AUTH_BASE_URL}customers/api/customer/details`;
export const EP_PUT_USER_NAME = `${AUTH_BASE_URL}customers/api/customer/profile/name`;
export const EP_UPLOAD_USER_IMAGE = `${AUTH_BASE_URL}customers/api/customer/avatar`;
export const EP_SEND_SMS_MOBILE = `${AUTH_BASE_URL}customers/api/customer/verification/sms/send`;
export const EP_RESEND_OTP = `${AUTH_BASE_URL}customers/api/customer/verification/email/send`;
export const EP_VERIFY_MOBILE_OTP = `${AUTH_BASE_URL}customers/api/customer/verification/sms/validate`;
export const EP_VERIFY_OTP = `${AUTH_BASE_URL}customers/api/customer/verification/email/validate`;
export const EP_GET_WATCHLIST = `${AUTH_BASE_URL}api/customer/watchlist?timeframe={{timeframe}}`;
export const EP_ADD_TO_WATCHLIST = `${AUTH_BASE_URL}customers/api/customer/watchlist`;
export const EP_GET_ALL_CURRENCY_CATEGORIES = `${AUTH_BASE_URL}statsservice/v2/api/markets/search`;
export const EP_GET_MARKET_CATEROGY_WISE = `${AUTH_BASE_URL}stats-service/api/markets/searchGrouped`;
export const EP_GET_ASSET_DETAILS = `${AUTH_BASE_URL}statsservice/api/markets/search`;
/*** Subscription Start ***/
export const EP_GET_SUBSCRIPTION_PLANS = `${AUTH_BASE_URL}payments/api/payments/v2/membership/availablePlans`;
// export const EP_GET_SELECTED_PLAN = `${AUTH_BASE_URL}payments/api/customer/planDetails`
export const EP_GET_SELECT_PLAN = `${AUTH_BASE_URL}payments/api/customer/membershipPlan`;
export const EP_GET_CANCEL_PLAN = `${AUTH_BASE_URL}payments/api/customer/membershipPlan`;
export const EP_GET_SAVED_CARDS = `${AUTH_BASE_URL}payments/api/checkout/customers/instruments`;
export const EP_SAVE_CARDS = `${AUTH_BASE_URL}payments/api/card/add`;
export const EP_INITIATE_PAYMENT = `${AUTH_BASE_URL}payments/api/checkout/payments`;
export const EP_GET_TRANSACTION_DETAILS = `${AUTH_BASE_URL}payments/api/customer/subscription`;
export const EP_GET_SAVED_CUST_CHECKOUT = `${AUTH_BASE_URL}payments/api/checkout/customers`;
export const EP_ADD_PAYMENT_METHOD = `${AUTH_BASE_URL}payments/api/checkout/customers/instruments`;
export const EP_DELETE_PAYMENT_METHOD = `${AUTH_BASE_URL}payments/api/checkout/instruments/{{ID}}`;
export const EP_DEFAULT_PAYMENT_METHOD = `${AUTH_BASE_URL}payments/api/checkout/instruments/{{ID}}/true`;

/*** Subscription End ***/

/*** Rewards Start ***/
export const EP_REWARDS_DETAILS = `${AUTH_BASE_URL}payments/api/customer/rewards`;
export const EP_WITHDRAWAL_HISTORY = `${AUTH_BASE_URL}payments/api/providers/payment`;
export const EP_REWARDS_HISTORY = `${AUTH_BASE_URL}payments/api/providers/payment/details/breakdown`;
export const EP_WITHDRAWAL_FUNDS = `${AUTH_BASE_URL}payments/api/providers/payment/info`;
export const EP_REQUEST_PAYMENT = `${AUTH_BASE_URL}payments/api/providers/payment`;
export const EP_EXISTING_PAYMENT = `${AUTH_BASE_URL}payments/api/affiliates/payment/info`;
export const EP_CANCEL_REQUEST = `${AUTH_BASE_URL}payments/api/providers/payment/cancel`;
/*** Rewards End ***/

/*** Trading Accounts Start ***/
export const EP_BECOME_LEADER = `${AUTH_BASE_URL}traders/api/providers/leader?performActions=true`;
export const EP_GET_PROVIDER_ACCOUNT_DETAIL = `${AUTH_BASE_URL}traders/api/providers/{{id}}/profile?bringNonPublic=false`;
export const EP_GET_BROKER_ACCOUNT_DETAIL = `${AUTH_BASE_URL}users/api/brokerAccounts/{{BROKER_ID}}/trading/details`;
export const EP_POST_BROKER_ACCOUNT_DETAIL = `${AUTH_BASE_URL}users/api/brokerAccounts/{{BROKER_ID}}/trading/details`;
export const EP_UPDATE_DESC = `${AUTH_BASE_URL}traders/api/providers/leader/strategy/update`;
export const EP_LEADER_IMPORT_HISTORY = `${AUTH_BASE_URL}traders/api/trader/{id}/import/history`;
/*** Trading Accounts End ***/

/*** Dashboad portfolio Start ***/
export const EP_PROVIDER_LIST = `${AUTH_BASE_URL}followers/v3/api/user/providers/following/settings`;
export const EP_PROVIDER_OPEN_TRADES = `${AUTH_BASE_URL}trading/api/user/trades/open?`;
export const EP_PROVIDER_PENDING_TRADES = `${AUTH_BASE_URL}trading/api/user/trades/orders?targetBrokerAccountId={{ID}}&page={{page}}&size={{size}}`;
export const EP_PROVIDER_HISTORY_TRADES = `${AUTH_BASE_URL}trading/api/user/trades/history?targetBrokerAccountId={{ID}}&page={{page}}&size={{size}}`;
export const EP_OPEN_TRADE_DETAILS = `${AUTH_BASE_URL}trading/api/user/trades/{{BROKER_TICKET}}/open/details?targetBrokerAccountId={{targetBrokerAccountId}}`;
export const EP_CLOSED_TRADE_DETAILS = `${AUTH_BASE_URL}trading/api/user/trades/{{BROKER_TICKET}}/history/details?targetBrokerAccountId={{targetBrokerAccountId}}`;
export const EP_PENDING_ORDER_DETAILS = `${AUTH_BASE_URL}trading/api/user/trades/{{BROKER_TICKET}}/orders/details?targetBrokerAccountId={{targetBrokerAccountId}}`;
export const EP_CHANGE_PASSWORD = `${AUTH_BASE_URL}customers/api/customer/password/update`;
/*** Dashboad portfolio End ***/

export const EP_GET_STRATEGY_WISE_CURRENCY = `${AUTH_BASE_URL}statsservice/api/markets/search`;
export const EP_GET_ASSET_TRADES = `${AUTH_BASE_URL}trading/api/user/trades/open?timeframe=10000&calculateProfit=true&cid={{cid}}`;
export const EP_GET_MARKET_NEWS = `https://betapi.portfolios.com/get/news`;
export const EP_GET_ASSET_NEWS = `https://betapi.portfolios.com/get/newsby/symbol`;
export const EP_GET_ASSET_PENDING_TRADES = `${AUTH_BASE_URL}trading/api/user/trades/orders?timeframe=10000&calculateProfit=true&cid={{cid}}`;
export const EP_GET_ASSET_CLOSED_TRADES = `${AUTH_BASE_URL}trading/api/user/trades/history?timeframe=10000&calculateProfit=true&cid={{cid}}`;
export const EP_GET_CURRENCY_WITH_ID = `${AUTH_BASE_URL}utils/api/currencies/names`;
export const EP_GET_CUSTOMER_DETAIL = `${AUTH_BASE_URL}api/customer`;
export const EP_GET_CONVESRTION_STATS = `${AUTH_BASE_URL}api/trading/conversion?targetBrokerAccountId={{ID}}`;
export const EP_ASSET_PLACE_ORDER = `${AUTH_BASE_URL}trading/api/trades/open/market?targetBrokerAccountId={{ID}}`;
export const EP_ASSET_PENDING_ORDER = `${AUTH_BASE_URL}trading/api/trades/open/pending?targetBrokerAccountId={{ID}}`;
export const EP_CLOSE_OPEN_ORDER = `${AUTH_BASE_URL}trading/api/trades/{{ID}}/closeMarket?targetBrokerAccountId={{targetBrokerAccountId}}`;
export const EP_CLOSE_PENDING_ORDER = `${AUTH_BASE_URL}trading/api/trades/{{ID}}/closePending?targetBrokerAccountId={{targetBrokerAccountId}}`;
export const EP_GET_TRADER_COPY_STATS = `${AUTH_BASE_URL}followers/v3/api/user/providers/following/settings?targetZuluAccountId={{ID}}`;

export const GET_QUESTIONARIES_STATUS = `${AUTH_BASE_URL}customers/api/customer/questionnaire/2`;

/* NEW  MICFOSERVICES */
export const EP_COPY_LEADER = `${AUTH_BASE_URL}followers/api/user/copy/provider?targetZuluAccountId={{ID}}`;
export const EP_UPDATE_COPY_STATEGY = `${AUTH_BASE_URL}followers/api/user/copy/provider?targetZuluAccountId={{ID}}`;
export const EP_DELETE_COPY_STATEGY = `${AUTH_BASE_URL}followers/api/user/copy/provider/{{LEADER_ID}}?targetZuluAccountId={{ID}}&closeAllTrades={{ans}}`;

export const EP_GET_MARKET_CLOSE_DAYS = `${AUTH_BASE_URL}utils/api/currency/tradingSession?currencyName={{ASSET}}`;
export const EP_ASSET_SEARCH_LIST = `${AUTH_BASE_URL}statsservice/api/markets/search`;
export const EP_GET_USER_DASHBOARD_DATA = `${AUTH_BASE_URL}followers/api/followers/performance/snapshot?`;
export const EP_GET_ALL_BASECURRENCY = `${AUTH_BASE_URL}utils/api/baceCurrency/all/byId`;
export const EP_GET_USER_CHART_WITH_LEADER = `${AUTH_BASE_URL}trading/api/user/trades/history/chart?`;
export const EP_GET_ALL_NOTIFICATIONS = `${AUTH_BASE_URL}notifications/api/account/trade/logs?`;
export const EP_UPDATE_SL = `${AUTH_BASE_URL}trading/api/trades/{{TICKET}}/stop?targetBrokerAccountId={{ID}}`;
export const EP_UPDATE_TP = `${AUTH_BASE_URL}trading/api/trades/{{TICKET}}/limit?targetBrokerAccountId={{ID}}`;
export const EP_GET_LEVERAGE_LIST = `${AUTH_BASE_URL}utils/api/tools/leverages`;

/* Calendar Start */
export const EP_CALENDAR = `https://betapi.portfolios.com/get/calendar`;
export const EP_CALENDAR_TICKER_HISTORY = `https://betapi.portfolios.com/get/ticker/history`;
/* Calendar End */

/* Feedback Start */
export const EP_FEEDBACK = `${AUTH_BASE_URL}users/api/user/feedback`;
/* Feedback End */

/* Blogs */
// export const EP_GET_BLOG_CATEGORY_WISE_DATA = `https://blog.zulutrade.com/wp-json/wp/v2/posts?categories={{category}}&per_page={{limit}}&page={{page}}`
// export const EP_GET_BLOG_DETAIL = `https://blog.zulutrade.com/wp-json/wp/v2/posts?slug={{slug}}`
// export const EP_GET_BLOG_DETAIL_ID = `https://blog.zulutrade.com/wp-json/wp/v2/posts/{{slug}}`
export const EP_GET_BLOG_CATEGORY_WISE_DATA = `https://mobilecharts.zulutrade.com/blog/get/posts?categories={{category}}&per_page={{limit}}&page={{page}}`;
export const EP_GET_BLOG_DETAIL = `https://mobilecharts.zulutrade.com/blog/get/posts?slug={{slug}}`;
export const EP_GET_BLOG_DETAIL_ID = `https://mobilecharts.zulutrade.com/blog/get/posts/{{slug}}`;
/* Blogs */

export const EP_BROKER_RAISE_QUERY = `${AUTH_BASE_URL}brokers/api/brokers/request/new`;
export const EP_AFFILATE_LANDED = `${AUTH_BASE_URL}attributions/api/attribution/landed`;

// Notification Setting
export const NOTIFICATION_SETTING = `${AUTH_BASE_URL}notifications/api/preferences`;
export const EP_GET_BROKER_STABILITY_QUESTIONS_LIST = `${AUTH_BASE_URL}customers/api/customer/questionnaire?status=PENDING`;

// Account Setting
export const MONITOR_ACCOUNT_SETTING = `${AUTH_BASE_URL}users/api/brokerAccounts/settings`;
export const NEWSLETTER_SETTING = `${AUTH_BASE_URL}customers/api/customer/contact?`;

// Close All Position's
export const CLOSE_ALL_TRADES = `${AUTH_BASE_URL}trading/api/user/trades/close`;

// Cancelled Order Log's
export const CANCELLED_ORDERS_LOG = `${AUTH_BASE_URL}trading/api/user/trades/cancelledOrders?`;

// Trade History in Dashboard
export const TRADE_HISTORY = `${AUTH_BASE_URL}trading/api/user/trades/history?`;
export const TRADE_HISTORY_CHART = `${AUTH_BASE_URL}trading/api/user/trades/history/chart?`;
export const TRADE_HISTORY_LEADERS = `${AUTH_BASE_URL}followers/api/user/{{brokerID}}/followed/providers`;
export const TRADE_HISTORY_CURRENCY = `${AUTH_BASE_URL}trading/api/user/trades/history/currency?`;
//Unlimit
export const GET_PAYMENT_TYPE = `${AUTH_BASE_URL}payments/api/customer/membershipPlan/preferredPsp`;
export const UNLIMIT_NEW_PAYMENT = `${AUTH_BASE_URL}payments/api/unlimit/payments`;
export const UNLIMIT_RECURRING_PAYMENT = `${AUTH_BASE_URL}payments/api/unlimit/payments/recurring/filling`;
export const UNLIMIT_INSTRUMENT = `${AUTH_BASE_URL}payments/api/unlimit/instruments`;

// Pro-Rata
export const PRORATA = `${AUTH_BASE_URL}followers/api/user/copy/provider/prorata?targetZuluAccountId={{ID}}`;

//Contact Us
export const CONTACT_US = `${AUTH_BASE_URL}brokers/api/brokerPartnership/contact`;
