<template>
    <div class="container">
        <div class="row text-center mt-3">
            <div class="no-posts-section">
                <v-lazy-image  src="/assets/images/noposts.png" :alt="$t('header.title18')" />
                <p class="mt20">{{page != 'marketList' ? $t('marketList.text19') : $t('marketList.text23')}}</p>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: ["page"],
}
</script>