<template>
    <a href="/" class="lightTheme navbar-brand d-flex p-0" ><img :width="COMMON.isCobrandedDomain() ? 150 : 180" :height="COMMON.isCobrandedDomain() ? '' : 40" :src="(COMMON.isCobrandedDomain()) ? `${static_vars.zuluTrade}${store.flavordata?.whitelabelLogo || '/assets/images/logo.svg'}` : '/assets/images/logo.svg'" :alt="$t('head.text21')" :title="$t('header.zulu_trade')" /></a>
    <a href="/" class="darkTheme navbar-brand d-none p-0" ><img :width="COMMON.isCobrandedDomain() ? 150 : 180" :height="COMMON.isCobrandedDomain() ? '' : 40" :src="(COMMON.isCobrandedDomain()) ? `${static_vars.zuluTrade}${store.flavordata?.whitelabelLogo || '/assets/images/logo.svg'}` : '/assets/images/whiteLogo.svg'" :alt="$t('head.text21')" :title="$t('header.zulu_trade')" /></a>
</template>
<script>
import { myStore } from "@/store/pinia-store";
export default {
    setup() {
        const store = myStore();
        return { store }
    },
    data() {
        return {
            appMode : process.env.VUE_APP_MODE
        };
    },
}
</script>