<template>
    <input id="searchInput" type="search" class="form-control" :placeholder="isLeaderPage() ? $t('global_search.text3') : $t('global_search.text4')" @input="Searching($event)" v-model="searchdata"  />
    <a href="javascript:void(0)" class="searchIcon"><vue-feather size="18" type="search"></vue-feather> </a>
    <div class="dropdown_menu_animated no-animate p-0 globalSearch show">
        <!-- :class="[{'show' : searchdata && searchdata.length}]" -->
        <div class="flex-between mb-2 px-3 position-sticky">
            <p class="medium midgray mb-0">{{(isLeaderPage()) ? $t('global_search.text5') : $t('global_search.text6')}}</p>
            <p class="f-14 midgray mb-0" v-if="searchdata?.length">{{(isLeaderPage()) ? store.searchTradersList.length : marketAssetList.length}} {{$t('global_search.text1')}}</p>
        </div>
        <div v-if="isLeaderPage() && (store.searchTradersList.length || store.topTrdaersList?.length)">
            <ul class="globaListSearch" v-if="store.searchTradersList.length && searchdata?.length">
                <li v-for="item,index in store.searchTradersList" :key="index">
                    <a href="javascript:void(0)" class="d-flex align-items-center justify-content-between" @click="detailPage(item,'LEADER','searchResult')" data-bs-dismiss="modal">
                        <span class="d-flex align-items-center">
                            <v-lazy-image width="24" height="24" class="rounded-circle me-3" :src="(!item.photoApproved) ? (item.customerAvatarUrl) ? item.customerAvatarUrl : static_vars.imagesURL+'webservices/Image.ashx?type=provider&size=XS&id='+item.providerId+'&ignore=false' : static_vars.imagesURL+'webservices/Image.ashx?type=provider&size=XS&id='+item.providerId+'&ignore=false'" :alt="item.name" :title="item.name" />
                            <span class="me-3 f-17 menutext medium">{{item.name}}</span>
                        </span>
                        <span class="f-16" :class="parseFloat(item.annualizedRorBasedRoi) > 0.0  ? 'green' : 'red'">
                            {{parseFloat(item.annualizedRorBasedRoi) > 0.0 ? '+' : ''}}{{parseFloat(item.annualizedRorBasedRoi || 0).toFixed(2)}}%
                        </span>
                    </a>
                </li>
            </ul>
            <ul class="globaListSearch" v-if="store.topTrdaersList?.length && store.topTrdaersList[0].result?.length && !searchdata?.length">
                <li v-for="item,index in store.topTrdaersList[0].result" :key="index">
                    <a href="javascript:void(0)" class="d-flex align-items-center justify-content-between" @click="detailPage(item,'LEADER','withoutSearchResult')" data-bs-dismiss="modal">
                        <span class="d-flex align-items-center">
                            <v-lazy-image width="24" height="24" class="rounded-circle me-3" :src="(!item.photoApproved) ? (item.trader.customerAvatarUrl) ? item.trader.customerAvatarUrl : static_vars.imagesURL+'webservices/Image.ashx?type=provider&size=XS&id='+item.trader.providerId+'&ignore=false' : static_vars.imagesURL+'webservices/Image.ashx?type=provider&size=XS&id='+item.trader.providerId+'&ignore=false'" :alt="item.trader.name" :title="item.trader.name" />
                            <span class="me-3 f-17 menutext medium">{{item.trader.profile.name}}</span>
                        </span>
                        <span class="f-16" :class="parseFloat(item.trader.timeframeStats[Object.keys(item.trader.timeframeStats)[0]].annualizedRorBasedRoi) > 0.0  ? 'green' : 'red'">
                            {{parseFloat(item.trader.timeframeStats[Object.keys(item.trader.timeframeStats)[0]].annualizedRorBasedRoi) > 0.0 ? '+' : ''}}{{parseFloat(item.trader.timeframeStats[Object.keys(item.trader.timeframeStats)[0]].annualizedRorBasedRoi || 0).toFixed(2)}}%
                        </span>
                    </a>
                </li>
            </ul>
        </div>
        <div class="no-posts-section d-flex flex-column align-items-center" v-if="isLeaderPage() && !store.searchTradersList.length && searchdata?.length">
            <v-lazy-image src="/assets/images/noposts.png" :alt="$t('header.title18')" />
            <p class="mt-3">{{$t('global_search.text2')}}</p>
        </div>
        <ul class="globaListSearch" v-if="isMarketPage() && marketAssetList.length">
            <li v-for="item,index in marketAssetList" :key="index">
                <!-- <a href="javascript:void(0)" class="d-flex align-items-center justify-content-between" @click="detailPage(item,'MARKET')">
                    <span class="d-flex align-items-center">
                        <span class="me-3">
                            <v-lazy-image width="24" height="24" class="euCurrency" :src="static_vars.marketImageSURL+item.symbol.toUpperCase().replace(/\//g, '')+'.svg'" :alt="item.symbol.toString()" :title="item.symbol.toString()" />
                        </span>
                        <span class="me-2 f-15 menutext minWidthName medium">{{item.symbol}}</span>
                    </span>
                    <span class="d-flex align-items-center">
                        <span class="f-15 px-1 midgray me-1 minWidthValue me-2">{{Object.keys(store.allPrices).length && store.allPrices[item.symbol] && store.allPrices[item.symbol].buyPrice ? store.allPrices[item.symbol].buyPrice : item.currentPrice}}</span>
                        <span class="px-1" :class="(parseFloat(item.lastChangePercentage) > 0.0) ? 'green' : 'red'">{{(parseFloat(item.lastChangePercentage) > 0.0) ? '+' : ''}}{{parseFloat(item.lastChangePercentage).toFixed(2) || 0}}%</span>
                    </span>
                </a> -->
                <router-link :to="{ name: 'rates', query: { insID: item.id }}" class="d-flex align-items-center justify-content-between" @click="searchdata=''">
                    <span class="d-flex align-items-center" data-bs-dismiss="modal">
                        <span class="me-3">
                            <v-lazy-image width="24" height="24" class="euCurrency" :src="static_vars.marketImageSURL+item.symbol.toUpperCase().replace(/\//g, '')+'.svg'" :alt="item.symbol.toString()" :title="item.symbol.toString()" />
                        </span>
                        <span class="me-2 f-15 menutext minWidthName medium">{{item.symbol}}</span>
                    </span>
                    <span class="d-flex align-items-center" data-bs-dismiss="modal">
                        <span class="f-15 px-1 midgray me-1 minWidthValue me-2">{{Object.keys(store.allPrices).length && store.allPrices[item.symbol] && store.allPrices[item.symbol].buyPrice ? store.allPrices[item.symbol].buyPrice : item.currentPrice}}</span>
                        <span class="px-1" :class="(parseFloat(item.lastChangePercentage) > 0.0) ? 'green' : 'red'">{{(parseFloat(item.lastChangePercentage) > 0.0) ? '+' : ''}}{{parseFloat(item.lastChangePercentage).toFixed(2) || 0}}%</span>
                    </span>
                </router-link>
            </li>
        </ul>
        <div class="no-posts-section d-flex flex-column align-items-center" v-if="isMarketPage() && !marketAssetList.length">
            <v-lazy-image src="/assets/images/noposts.png" :alt="$t('header.title18')" />
            <p class="mt-3">{{$t('global_search.text2')}}</p>
        </div>
    </div>
</template>
<script>
    import { myStore } from "@/store/pinia-store";
    export default {
        setup() {
            const store = myStore();
            return { store };
        },
        data() {
            return {
                searchdata: "",
            };
        },
        watch:{
            "$route.path"(){
                this.searchdata = ""
            },
        },
        computed:{
            marketAssetList(){
                if(this.store.categoryWiseMarket?.marketsByCategory &&  Object.keys(this.store.categoryWiseMarket?.marketsByCategory).length){
                    let data = [];
                    for(var key in this.store.categoryWiseMarket?.marketsByCategory){
                        data = data.concat(this.store.categoryWiseMarket?.marketsByCategory[key])
                    }
                    if(data.length){
                        if(this.searchdata){
                            return data.filter(i => i.name.toLowerCase().includes(this.searchdata.toLowerCase()));
                        }else{
                            return data
                        }
                    }else{
                        return []
                    }
                }else{
                    return []
                }
            }
        },
        methods: {
            detailPage(item, type, location) {
                if (item) {
                    let path = "";
                    let providerId = location=='searchResult' ? item.providerId : item.trader.providerId
                    if (type == "LEADER") {
                        if (this.$route.name != "traderDetail") {
                            path = `/trader/${providerId}/trading?t=10000&m=1`;
                            window.location.href = path;
                        } else {
                            if (parseInt(this.$route.params.id) != parseInt(providerId)) {
                                path = `/trader/${providerId}/trading?t=10000&m=1`;
                                window.location.href = path;
                            }
                        }
                    }
                    this.searchdata = "";
                }
            },
            Searching(e) {
                if (e.target.value) {
                    this.searchdata = e.target.value;
                    if(this.searchdata.length){
                        if (this.isLeaderPage() ) {
                            this.store.callFollowersCompareList({}, false, this.page, this.searchdata, "global");
                        }
                        // if (this.isMarketPage()) {
                        //     this.store.callassetSearchList({ searchText: this.searchdata }, false);
                        // }
                    }
                } else {
                    this.searchdata = "";
                }
            },
            isMarketPage() {
                if (this.$route.name == "rates" || this.$route.name == "market_details" || this.$route.name == "all_market" || this.$route.name == "dashboard_portfolio") {
                    return true;
                } else {
                    return false;
                }
            },
            isLeaderPage() {
                if (this.$route.name == "traders" || this.$route.name == "view_all_leader" || this.$route.name == "traderDetail" || this.$route.name == "dashboard_portfolio") {
                    return true;
                } else {
                    return false;
                }
            },
        },
        mounted(){
            if(this.store.user.access_token){
                if(!this.store.topTrdaersList?.length){
                    this.store.callTopTradersList({}, false)
                }
            }
        }
    };
</script>
